'use strict';
import angular from 'angular';
import _ from 'lodash';

import exportBuilderItemHtmlUrl from './exportbuilder.item.html';
import exportBuilderItemWidgetHtmlUrl from './exportbuilder.item.widget.html';
import exportBuilderItemImageHtmlUrl from './exportbuilder.item.image.html';
import exportBuilderItemTextHtmlUrl from './exportbuilder.item.text.html';
import exportBuilderItemShapeHtmlUrl from './exportbuilder.item.shape.html';
import exportBuilderItemIconHtmlUrl from './exportbuilder.item.icon.html';
import { CurrencyConstants } from 'coreModules/shared/scripts/app.constants';
import $ from 'jquery';

angular.module('exportbuilder.components')
    .component('exportBuilderItem', {
        bindings: {
            item: '<',
            pageIndex: '<',
            pageId: '<'
        },
        templateUrl: exportBuilderItemHtmlUrl,
        controller: ExportBuilderItemController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemWidget', {
        bindings: {
            item: '<'
        },
        templateUrl: exportBuilderItemWidgetHtmlUrl,
        controller: ExportBuilderItemWidgetController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemImage', {
        bindings: {
            item: '<'
        },
        templateUrl: exportBuilderItemImageHtmlUrl,
        controller: ExportBuilderItemImageController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemText', {
        bindings: {
            item: '<',
            pageIndex: '<',
            pageId: '<'
        },
        templateUrl: exportBuilderItemTextHtmlUrl,
        controller: ExportBuilderItemTextController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemShape', {
        bindings: {
            item: '<'
        },
        templateUrl: exportBuilderItemShapeHtmlUrl,
        controller: ExportBuilderItemShapeController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemIcon', {
        bindings: {
            item: '<'
        },
        templateUrl: exportBuilderItemIconHtmlUrl,
        controller: ExportBuilderItemIconController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderItemController(
    $timeout,
    ExportBuilderDashboardService,
    ExportBuilderDashboardItemService,
    ExportBuilderFacadeUIService,
    ReportElementTypes,
    DrawOptionPanelFactory,
    PubSub,
    $ExportBuilderItemDirectiveEvents,
    $DrawOptionPanelEvents,
    ReportElementBaseOptions,
    UIColor,
    ExportBuilderDashboardUIModelFactory,
    $ExportBuilderDashboardModelEvents,
    $ExportBuilderDashboardEvents,
    ExportBuilderElementActionService,
    ReportElementDesignOptionConstants,
    ExportBuilderPrefixes,
    WidgetType,
    ExecutiveSummaryFactory,
    UIExecutiveSummaryPanelFactory,
) {
    const vm = this;

    /**
     * @type {ReportElementModel}
     */
    vm.item;

    vm.refreshElement = false;
    vm.loading = false;
    vm.ReportElementTypes = ReportElementTypes;
    vm.ExportBuilderPrefixes = ExportBuilderPrefixes;
    vm.canEditReport = ExportBuilderDashboardService.canEditReport();
    vm.isExecutiveSummaryWidget = vm.item.isTypeWidget() && vm.item.widget.type === WidgetType.EXECUTIVESUMMARY;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.itemClasses = itemClasses;
    vm.positionStyle = positionStyle;
    vm.isLoading = isLoading;
    vm.itemInnerStyle = itemInnerStyle;
    vm.itemInnerClass = itemInnerClass;
    vm.toggleFocus = toggleFocus;
    vm.onItemRightClick = onItemRightClick;
    vm.onDoubleClick = onDoubleClick;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function itemClasses() {
        let classes = [];

        if (vm.item.isEditing) {
            classes.push('editing');
        }

        if (vm.item.focused) {
            classes.push('focused');
        }

        if (ExportBuilderFacadeUIService.getIsOutlineActive()) {
            classes.push('show-lines');
        }

        if (vm.item.isTypeShape()) {
            classes.push(vm.item.metadata.shape_type)
        }

        if (vm.item.metadata.set_as_background) {
            classes.push('element-set-background');
        }

        if (ExportBuilderDashboardService.getBuilder().elements.length > 1) {
            classes.push('disable-resize');
            classes.push('multi-select-state');
        }

        if (vm.item.isInSpecialEditMode) {
            classes.push('is-in-special-edit-mode');
            classes.push('disable-resize');
        }

        return classes;
    }

    function positionStyle() {
        let styles =  {
            position: 'absolute',
            top: vm.item.y_position + '%',
            left: vm.item.x_position + '%',
            height: vm.item.height + '%',
            width: vm.item.width + '%',
            'z-index': vm.item.z_index
        };

        if (!vm.item.snap_to_grid) {
            styles['left'] = vm.item.x_position + 'px';
            styles['top'] = vm.item.y_position + 'px';
            styles['width'] = vm.item.width + 'px';
            styles['height'] = vm.item.height + 'px';
        }

        return styles;
    }

    function isLoading() {
        return vm.loading || _.isNil(vm.item.type) || (vm.item.id.contains(ExportBuilderPrefixes.ITEMID) && vm.item.type === ReportElementTypes.WIDGET);
    }

    function itemInnerStyle() {
        let styles = {};

        if (vm.item.metadata.design_options) {
            if (vm.item.metadata.base_options[ReportElementBaseOptions.MIRROR_X]) {
                if (!styles.transform) {
                    styles.transform = '';
                }
                styles.transform += 'rotateX(180deg) '
            }

            if (vm.item.metadata.base_options[ReportElementBaseOptions.MIRROR_Y]) {
                if (!styles.transform) {
                    styles.transform = '';
                }
                styles.transform += 'rotateY(180deg) '
            }
        }

        if (vm.item.metadata.base_options) {
            if (vm.item.metadata.base_options[ReportElementBaseOptions.BACKGROUND_COLOR]) {
                styles['background-color'] = vm.item.metadata.base_options[ReportElementBaseOptions.BACKGROUND_COLOR];
            }

            if (vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_RADIUS]) {
                styles['border-radius'] = vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_RADIUS] + 'px';
            }

            if (!vm.item.isTypeShape()) {
                if (!_.isUndefined(vm.item.metadata.base_options[ReportElementBaseOptions.SHADOW_X])
                    && !_.isUndefined(vm.item.metadata.base_options[ReportElementBaseOptions.SHADOW_Y])
                    && !_.isUndefined(vm.item.metadata.base_options[ReportElementBaseOptions.SHADOW_BLUR])
                    && vm.item.metadata.base_options[ReportElementBaseOptions.SHADOW_COLOR]) {
                    let options = vm.item.metadata.base_options;

                    styles['box-shadow'] = options[ReportElementBaseOptions.SHADOW_X] + 'px '
                        + options[ReportElementBaseOptions.SHADOW_Y] + 'px '
                        + options[ReportElementBaseOptions.SHADOW_BLUR] + 'px '
                        + options[ReportElementBaseOptions.SHADOW_COLOR] + '';
                }

                if (vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_COLOR]
                    && vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_WIDTH]
                    && vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_TYPE]
                    && vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_OPACITY]) {
                    let options = vm.item.metadata.base_options;
                    let color = UIColor.hexToRGBA(options[ReportElementBaseOptions.BORDER_COLOR], options[ReportElementBaseOptions.BORDER_OPACITY] / 100);
                    styles['border'] = color + ' '
                        + options[ReportElementBaseOptions.BORDER_WIDTH] + 'px '
                        + options[ReportElementBaseOptions.BORDER_TYPE];
                }
            }

            if (vm.item.metadata.base_options[ReportElementBaseOptions.OPACITY] >= 0) {
                styles['opacity'] = vm.item.metadata.base_options[ReportElementBaseOptions.OPACITY] / 100;
            }
        }

        return styles;
    }

    function itemInnerClass() {
        const classes = [];
        
        if (
            vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_TYPE] ===
            ReportElementDesignOptionConstants.BORDER_TYPE.DOUBLE
        ) {
            classes.push("has-borders-8");
        }
        
        if (
            vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_TYPE] ===
                ReportElementDesignOptionConstants.BORDER_TYPE.SOLID
        ) {
            classes.push("has-borders-4");
        }

        return classes;
    }

    function toggleFocus(event) {
        event.stopPropagation();
        if (vm.canEditReport) {
            !vm.item.focused && ExportBuilderFacadeUIService.focusOnElement(vm.item);
            /**
             * load executive summaries side panel if it is executive summary widget element
             */
            if (vm.item.isTypeWidget() && vm.item.widget.type === WidgetType.EXECUTIVESUMMARY) {
                UIExecutiveSummaryPanelFactory.initPanel();
            }
        }
    }

    function onItemRightClick(position, event) {
        if (isLoading()) {
            return;
        }
        PubSub.emit($ExportBuilderDashboardEvents.RIGHT_CLICK_MENU, {
            position: position,
            type: 'element',
            model: vm.item
        });
    }

    function onDoubleClick() {
        if (this.item.isGeoWidget()) {
            this.item.isInSpecialEditMode = true;
            ExportBuilderDashboardService.setWidgetModelForSpecialEdit(this.item.widget);
            PubSub.emit($ExportBuilderDashboardEvents.ON_SPECIAL_EDIT_MODE + this.item.id, true);
        }
    }

    function _onPositionChange(data) {
        ExportBuilderElementActionService.setCoordinates(data);
    }

    function _onSizeChange(data) {
        ExportBuilderDashboardService.updateItem(vm.item, {
            width: data.width,
            height: data.height
        }, data.noSave);
    }

    function _onCoordinateChange(data) {
        ExportBuilderElementActionService.setCoordinates(data);
    }

    function _onWidgetExportDrawOptionsChanged(data) {
        // data.layoutId, data.drawOptions, data.drawLocation, data.autoPersist
        vm.item.widget.metadata.draw_options = data.drawOptions;
        if (data.autoPersist) {
            ExportBuilderDashboardService.updateItem(vm.item);
        }
    }

    /**
     * When item is created for the first time,
     *  must update PubSub events since the item.id changed
     * @private
     */
    function _onNewCreatedItem() {
        _unregisterEvents();
        _registerEvents();
    }

    function _onRefreshElementNeeded() {
        vm.refreshElement = true;
        $timeout(function () {
            vm.refreshElement = false;
        });
    }

    function _onSetLoadingState(value) {
        vm.loading = value;
    }

    function _registerEvents() {
        if (vm.item.type === ReportElementTypes.WIDGET) {
            PubSub.on($DrawOptionPanelEvents.REPORT_STUDIO_WIDGET_DRAW_OPTIONS + vm.item.widget.id, _onWidgetExportDrawOptionsChanged);
        }
        PubSub.on($ExportBuilderItemDirectiveEvents.ON_POSITION_CHANGE+vm.item.id, _onPositionChange);
        PubSub.on($ExportBuilderItemDirectiveEvents.ON_SIZE_CHANGE+vm.item.id, _onSizeChange);
        PubSub.on($ExportBuilderItemDirectiveEvents.ON_COORDINATE_CHANGE+vm.item.id, _onCoordinateChange);
        PubSub.on($ExportBuilderDashboardModelEvents.ON_NEW_ITEM_CREATED+vm.item.id, _onNewCreatedItem);
        PubSub.on($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED+vm.item.id, _onRefreshElementNeeded);
        PubSub.on($ExportBuilderDashboardEvents.SET_LOADING_STATE+vm.item.id, _onSetLoadingState);
    }

    function _unregisterEvents() {
        PubSub.off($DrawOptionPanelEvents.REPORT_STUDIO_WIDGET_DRAW_OPTIONS, _onWidgetExportDrawOptionsChanged);
        PubSub.off($ExportBuilderItemDirectiveEvents.ON_POSITION_CHANGE+vm.item.id, _onPositionChange);
        PubSub.off($ExportBuilderItemDirectiveEvents.ON_SIZE_CHANGE+vm.item.id, _onSizeChange);
        PubSub.off($ExportBuilderItemDirectiveEvents.ON_COORDINATE_CHANGE+vm.item.id, _onCoordinateChange);
        PubSub.off($ExportBuilderDashboardModelEvents.ON_NEW_ITEM_CREATED+vm.item.id, _onNewCreatedItem);
        PubSub.off($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED+vm.item.id, _onRefreshElementNeeded);
        PubSub.off($ExportBuilderDashboardEvents.SET_LOADING_STATE+vm.item.id, _onSetLoadingState);        }
}

/**
 * @ngInject
 */
function ExportBuilderItemWidgetController() {
    const vm = this;

    vm.$onInit = $onInit;

    function $onInit() {

    }
}

/**
 * @ngInject
 */
function ExportBuilderItemImageController(
    ReportElementStylesUIFactory
) {
    const vm = this;

    vm.$onInit = $onInit;
    vm.getClasses = getClasses;

    function $onInit() {

    }

    function getClasses() {
        let classes = ReportElementStylesUIFactory.getElementImageClasses(vm.item);

        return classes;
    }
}

/**
 * @ngInject
 */
function ExportBuilderItemTextController(
    ReportElementStylesUIFactory,
    ExportBuilderDashboardItemService,
) {
    const vm = this;
    vm.isEditing = false;

    vm.$onInit = $onInit;
    vm.onTextDoubleClick = onTextDoubleClick;
    vm.onTextChange = onTextChange;
    vm.onClickOutside = onClickOutside;
    vm.textStyles = textStyles;
    vm.textClasses = textClasses;

    function $onInit() {

    }

    function onTextDoubleClick() {
        vm.isEditing = true;
    }

    function onClickOutside() {
        vm.isEditing = false;
    }

    function onTextChange() {
        ExportBuilderDashboardItemService.setTextTitle(vm.item, vm.item.metadata.design_options.text);
    }

    function textStyles() {
        let styles = ReportElementStylesUIFactory.getElementTextStyles(vm.item);

        return styles;
    }

    function textClasses() {
        let classes = ReportElementStylesUIFactory.getElementTextClasses(vm.item);

        return classes;
    }
}

/**
 * @ngInject
 */
function ExportBuilderItemShapeController(
    ReportElementShapeDesignOptions,
    ReportElementBaseOptions,
    UIColor
) {
    const vm = this;
    vm.shapeStyles = shapeStyles;
    vm.shapeClasses = shapeClasses;

    function shapeStyles() {
        let styles = {};

        if (vm.item.metadata.design_options[ReportElementShapeDesignOptions.BORDER_RADIUS]) {
            styles['border-radius'] = vm.item.metadata.design_options[ReportElementShapeDesignOptions.BORDER_RADIUS];
        }

        // override border-radius
        if (vm.item.isShapeSquare()) {
            styles['border-radius'] = vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_RADIUS];
        }

        if (vm.item.metadata.design_options[ReportElementShapeDesignOptions.BACKGROUND_COLOR]) {
            styles['background-color'] = vm.item.metadata.design_options[ReportElementShapeDesignOptions.BACKGROUND_COLOR];
        }

        if (vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_COLOR]
            && vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_WIDTH]
            && vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_TYPE]
            && vm.item.metadata.base_options[ReportElementBaseOptions.BORDER_OPACITY]) {
            let options = vm.item.metadata.base_options;
            let color = UIColor.hexToRGBA(options[ReportElementBaseOptions.BORDER_COLOR], options[ReportElementBaseOptions.BORDER_OPACITY] / 100);
            styles['border'] = color + ' '
                + options[ReportElementBaseOptions.BORDER_WIDTH] + 'px '
                + options[ReportElementBaseOptions.BORDER_TYPE];
        }

        if (!_.isUndefined(vm.item.metadata.base_options[ReportElementBaseOptions.SHADOW_X])
            && !_.isUndefined(vm.item.metadata.base_options[ReportElementBaseOptions.SHADOW_Y])
            && !_.isUndefined(vm.item.metadata.base_options[ReportElementBaseOptions.SHADOW_BLUR])
            && vm.item.metadata.base_options[ReportElementBaseOptions.SHADOW_COLOR]) {
            let options = vm.item.metadata.base_options;

            styles['box-shadow'] = options[ReportElementBaseOptions.SHADOW_X] + 'px '
                + options[ReportElementBaseOptions.SHADOW_Y] + 'px '
                + options[ReportElementBaseOptions.SHADOW_BLUR] + 'px '
                + options[ReportElementBaseOptions.SHADOW_COLOR] + '';
        }

        return styles;
    }

    function shapeClasses() {
        let classes = [];

        return classes;
    }
}


/**
 * @ngInject
 */
function ExportBuilderItemIconController(
    PubSub,
    PageSpecifications,
    ExportBuilderDashboardService,
    ReportElementIconDesignOptions,
    ExportFactory
) {
    let vm = this;
    vm.iconStyles = iconStyles;
    vm.iconClasses = iconClasses;
    vm.isExporting = isExporting;

    function iconStyles() {
        let styles = {};
        let report = ExportBuilderDashboardService.getReport();

        let height = vm.item.getHeight();
        let width = vm.item.getWidth();

        if (vm.item.snap_to_grid) {
            height = report.getPageHeight() * vm.item.getHeight() / 100;
            width = report.getPageWidth() * vm.item.getWidth() / 100;
        }

        if (!isExporting()) {
            styles['font-size'] = Math.min(height, width) + 'px';
        } else { // need to use separate styles for svg
            styles['width'] = width + 'px';
            styles['height'] = height + 'px';
        }

        if (vm.item.metadata.design_options[ReportElementIconDesignOptions.ICON_COLOR]) {
            const name = isExporting() ? 'fill' : 'color';
            styles[name] = vm.item.metadata.design_options[ReportElementIconDesignOptions.ICON_COLOR];
        }

        return styles;
    }

    function iconClasses () {
        let classes = [];

        classes.push(vm.item.metadata.icon);

        return classes;
    }

    function isExporting () {
        return ExportFactory.getIsExporting();
    }

}
