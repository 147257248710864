"use strict";

var Permission = {
  userRole: null,
  moduleName: {
    ALERT: "alert",
    APP: "app",
    ASYNC: "async",
    AUDITLOG: 'auditlog',
    BILLING: "billing",
    BUDGETPACING: "budgetpacing",
    CALCULATION: "calculation",
    CAMPAIGN: "campaign",
    CATEGORY: "category",
    CATEGORYDATA: "categorydata",
    DASHBOARDCHATGPTSUMMARIES: 'dashboardchatgptsummaries',
    CLIENT: "client",
    CLIENTCALCULATIONS: "clientcalculations",
    CLIENTCATEGORY: "clientcategory",
    CLIENTGROUP: "clientgroup",
    CLUSTER: "cluster",
    CLUSTERMEMBER: "clustermember",
    CLUSTERACCESS: "clusteraccess",
    COMPETITORRESEARCH: "competitorresearch",
    CONNECTIONSTATUS: "connectionstatus",
    DATALOADSTATUS: "dataloadstatus",
    DATASOURCE: "datasource",
    DASHBOARD: "dashboard",
    DESIGN: "design",
    EMAIL: "email",
    EXPORT: "export",
    GENERIC: "generic",
    GOAL: "goals",
    GOALDATA: "goaldata",
    HISTORY: "history",
    IMPORTWIZARD: "importwizard",
    INDUSTRYBENCHMARK: "industrybenchmark",
    IO: "io",
    KEYWORDTRACKING: "keywordtracking",
    LAYOUT: "layout",
    LEAD: "lead",
    LEADDETAILS: "leaddetails",
    LEADNOTES: "leadnotes",
    LEADNOTIFICATION: "leadnotification",
    MARGINRULES: "marginrules",
    MEMORYLOG: "memorylog",
    NAMINGCONVENTION: "namingconvention",
    NOTE: "note",
    NOTIFICATIONPROFILE: "notificationProfile",
    OAUTH: "oauth",
    OAUTHCLUSTERACCESS: "oauthclusteraccess",
    OBJECTIVE: "objective",
    PAGELAYOUTWIDGET: "pagelayoutwidget",
    PREFERENCE: "preference",
    PRODUCT: "product",
    QUICKREPORT: "quickreport",
    REPORTINGPROFILE: "reportingprofile",
    REPORTSTUDIO: "reportstudio",
    REPORTINGTEMPLATE: "reportingtemplate",
    ROLE: "role",
    SCHEDULEDREPORT: "scheduledreport",
    SERP: "serp",
    SERVICE: "service",
    SERVICESETTINGS: "servicesettings",
    SERVICEDATA: "servicedata",
    SERVICECLIENTMAPPING: "serviceclientmapping",
    SITEAUDITOR: "siteauditor",
    SMARTCAMPAIGN: "smartcampaign",
    SESSION: "session",
    TAG: "tag",
    SEO: "seo",
    SHARED: "shared",
    UDB: "udb",
    USER: "user",
    USERLOGIN: "userlogin",
    USERIMAGE: "userimage",
    WEBSITE: "website",
    WEBHOOKS: "webhooks",
    WIDGET: "widget",
    DASHBOARDEXECUTIVESUMMARIES: "dashboardexecutivesummaries",
    DATAEXPORTER: "dataexporter",
    SERVICELOOKUP: "servicelookup",
  },
  permissionKey: {
    CAN_READ_WRITE: "can_read_write",
    CAN_IMPERSONATE_OTHER_AGENTS: "can_impersonate_other_agents",
    CAN_PUBLISH_TO_LIBRARY: "can_publish_to_library",
    CAN_EDIT_REPORTING_OPTIONS: "can_edit_reporting_options",
    CAN_EDIT_DRAW_OPTIONS: "can_edit_draw_options",
    CAN_READ_WRITE_MANUAL_UPLOAD: "can_read_write_manual_upload",
    CAN_EDIT_WIDGET_ALERTS: "can_edit_widget_alerts",
    CAN_EDIT_WIDGET_ANNOTATIONS: "can_edit_widget_annotations",
    CAN_READ_WRITE_FILTER_SET: "can_read_write_filter_set",
    MANAGE_ACCESS_LISTS: "manage_access_lists"
  }
};

Permission.setUserRole = function(value) {
  this.userRole = value;
};

Permission.getPermission = function(
  moduleName,
  permissionKey = this.permissionKey.CAN_READ_WRITE
) {
  if (!this.userRole || !this.userRole.role_permissions) {
    return true;
  }
  if (!this.userRole.role_permissions[moduleName]) {
    throw new Error(`Module: '${moduleName}' does not exist`);
  }
  return this.userRole.role_permissions[moduleName][permissionKey];
};

Permission.hasPermission = function(
  moduleName,
  permissionKey = this.permissionKey.CAN_READ_WRITE,
  legacyPermission = true
) {
  if (!window.isNUI) {
    return legacyPermission;
  }
  return this.getPermission(moduleName, permissionKey) === true;
};

Permission.hasPermissionToRead = function(
  moduleName,
  legacyPermission = true,
  permissionKey = this.permissionKey.CAN_READ_WRITE
) {
  if (!window.isNUI) {
    return legacyPermission;
  }
  const permission = this.getPermission(moduleName, permissionKey);
  return permission === "read_only" || permission === "all";
};

Permission.hasPermissionToWrite = function(
  moduleName,
  legacyPermission = true,
  permissionKey = this.permissionKey.CAN_READ_WRITE
) {
  if (!window.isNUI) {
    return legacyPermission;
  }
  const permission = this.getPermission(moduleName, permissionKey);
  return permission === "all";
};

if (
  typeof define === "function" &&
  typeof define.amd === "object" &&
  define.amd
) {
  // AMD. Register as an anonymous module.
  define(function() {
    return Permission;
  });
} else if (typeof module !== "undefined" && module.exports) {
  module.exports = Permission.attach;
  module.exports.Permission = Permission;
} else {
  window.Permission = Permission;
}

export default Permission;
